/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function isMobileDevice() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

function createCookie(name,value,days) {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    expires = "; expires="+date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)===' ') { c = c.substring(1,c.length); }
    if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length,c.length); }
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name,"",-1);
}


(function($) {


  var wW = $(window).width();


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // console.log(theme_args);

        $('.archive__load-posts').click(function(){
          console.log('clicked');

          var button = $(this),
              data = {
                'action': 'loadmore',
                'query': theme_args.posts, // that's how we get params from wp_localize_script() function
                'page' : theme_args.current_page
          };

          $.ajax({
            url : theme_args.ajaxurl, // AJAX handler
            data : data,
            type : 'POST',
            beforeSend : function ( xhr ) {
              button.text('Loading...'); // change the button text, you can also add a preloader image
            },
            success : function( data ){
              if( data ) {
                button.before(data);
                button.text( 'LOAD MORE' ); // insert new posts
                theme_args.current_page++;
                if ( parseInt(theme_args.current_page) === parseInt(theme_args.max_page) ){
                  button.remove(); // if last page, remove the button

                }

              } else {
                button.remove(); // if no data, remove the button as well
              }
            }
          });
        });



        if( $('.homepage__hero__slider').length > 0 ) {
            $('.homepage__hero__slider').slick({
                autoplay:true,
                autoplaySpeed:4000,
                dots: false,
                infinite: true,
                slidesToShow: 1,
                prevArrow: false,
                nextArrow: false
            });
        }

        //Page bounceBack
        if( $('.bounceback-sliders__slider').length > 0 ) {
          $('.bounceback-sliders__slider').slick({
            autoplay:true,
            autoplaySpeed:4000,
            dots: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: false,
            centerPadding: '100px',
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }

            ],
            appendArrows: $('.bounceback-sliders__nav'),
            prevArrow: '<div class="bounceback-sliders__nav__arrow bounceback-sliders__nav__arrow__prev"><i class="fa fa-arrow-left" aria-hidden="true"></i></div>',
            nextArrow: '<div class="bounceback-sliders__nav__arrow bounceback-sliders__nav__arrow__next"><i class="fa fa-arrow-right" aria-hidden="true"></i></div>',
          });
        }


        if( $('.adult__slider__container').length > 0 ) {
          $('.adult__slider__container').slick({
            dots:true,
            autoplay:true,
            autoplaySpeed:4000,
            prevArrow: ' <div class="slick__slider__prev slick__slider__arrow"><img src="' + theme_args.theme_url + '/dist/images/left-white-arrow.png"></div>',
            nextArrow: ' <div class="slick__slider__next slick__slider__arrow"><img src="' + theme_args.theme_url + '/dist/images/right-white-arrow.png"></div>',
          });
        }

        if( $('.material-slider__container').length > 0 ) {
          $('.material-slider__container').slick({
            dots:true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }

            ],
            prevArrow: ' <div class="slick__slider__prev slick__slider__arrow"><img src="' + theme_args.theme_url + '/dist/images/left-white-arrow.png"></div>',
            nextArrow: ' <div class="slick__slider__next slick__slider__arrow"><img src="' + theme_args.theme_url + '/dist/images/right-white-arrow.png"></div>',
          });
        }


        $('.accordion__title').click(function () {
          clicked_id = $(this).parent().attr('id');
          $('.accordion').each(function() {
            if($(this).attr('id') === clicked_id) {
              // console.log($(this).attr('id'));
              $(this).toggleClass('accordion--active');
              $(this).find('.close-accordion').toggle();
              $(this).find('.open-accordion').toggle();
            } else {
              $(this).removeClass('accordion--active');
              $(this).find('.close-accordion').show();
              $(this).find('.open-accordion').hide();
            }
          });

          if($(this).parent().hasClass('accordion--active')) {

            screenOffset = 400;

            if(wW < 768) {
              screenOffset = 300;
            }

            $(this).next('.accordion__content').slideDown({
      				duration: 300,
      				done: function() {
      					$('html, body').animate({
      						scrollTop: $(this).offset().top - screenOffset
      					}, 10);
      				}
      			});
          }

        });

        $('#question-6 a').click(function(e) {
          e.preventDefault();

          $('#question-6').toggleClass('accordion--active');
          $('#question-16').toggleClass('accordion--active');
          $('#question-16 .accordion__title').find('.close-accordion').toggle();
          $('#question-16 .accordion__title').find('.open-accordion').toggle();
          $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top - 250,
          }, 500, 'linear');
        });

        $MobileMenu = $('.header__navigation');

        // Header Hamburger Menu Manipulation
        $('.header__hamburger').click(function(){
          if(wW < 1025) {
            $('.menu-item-has-children').click(function() {
              if ($(this).children('ul').is(':hidden') === true) {
                $(this).children('ul').slideDown();
                $(this).find('a').first().toggleClass('submenu-opened');
                return false;
              }

              if ($(this).children('ul').is(':visible') === true) {
                $(this).children('ul').slideUp();
                $(this).find('a').first().toggleClass('submenu-opened');
                return true;
              }

            });
          }
          $(this).toggleClass('is-active');
          $MobileMenu.toggleClass('menu-open');

        });


        var totalHeader = $('.header').outerHeight();
        $('.header__navigation_mobile').css('top', totalHeader+'px');


        var progress;

        // function move_quiz_form(event, formId, current_page){
        //   var gFormContent = $('.gform_wrapper');
        //   if (current_page === 1) {
        //     $('.the-quiz__welcome__next').append(gFormContent);
        //   } else {
        //     $('.the-quiz__form__content').append(gFormContent);
        //   }
        // }

        // $(document).bind('gform_post_render', move_quiz_form);

      //   $(document).bind('gform_page_loaded', function(event, form_id, current_page){

      //     move_quiz_form(event, form_id, current_page);
      //     //manages classes in the body
      //     var prevpage = parseInt(current_page) - 1;
      //     var nextpage = parseInt(current_page) + 1;
      //     var prev2page = parseInt(current_page) - 2;
      //     $('body').removeClass('page-'+ prevpage);
      //     $('body').removeClass('page-'+ nextpage);
      //     $('body').addClass('page-'+ current_page);

      //     //fix sliding issue on dropdowns
      //     $('.page-' + current_page + ' .ginput_container_select').click(function(){
      //       $(this).find('.chosen-results').scrollTop(0);
      //     });

      //     //changing style on dropdown select
      //     $('.page-' + current_page + ' .ginput_container_select').on('click','li',function(){
      //       $(this).parent().parent().parent().addClass('selected');
      //     });

      //     //add 'optional' to optional fields
      //     $('.page-' + current_page + ' .gfield.optional').each(function(){
      //       var content = $(this).find('label').html();
      //       $(this).find('label').html(content + ' <span class="small">Optional</span>');
      //     });


      //     //adds event listeners for the radio buttons
      //     $('.page-' + current_page + ' .gfield_radio label').click(function(){
      //       event.preventDefault();
      //       $(this).parent().addClass('clicked');
      //       $(this).prev().prop('checked','true');
      //       $(this).parent().siblings().removeClass('clicked');
      //       $(this).parent().siblings().find('input').prop('checked',false);

      //     });
      //     $('.page-' + current_page + ' .gfield_checkbox label').click(function(e){
      //       event.preventDefault();
      //       $(this).parent().toggleClass('clicked');
      //       if($(this).hasClass('clicked')){
      //         $(this).prev().prop('checked',true);
      //       } else {
      //         $(this).prev().prop('checked',false);
      //       }
      //     });

      //     $('.page-' + current_page + ' input[checked="checked"]').each(function(){
      //       $(this).parent().addClass('clicked');
      //     });


      //  });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };



  // Load Events
  $(document).ready(UTIL.loadEvents);


  // In Crisis Message Secondary Pages
  $('.in-crisis-arrow-splash-page').click(function() {
    if(wW < 767) {
      $('.in-crisis-box-splash-page').slideDown({
        start: function () {
          $(this).css({
            display: "flex"
          });
        }
      });
    } else {
      $('.in-crisis-box-splash-page').slideDown('faster');
    }
  });
  $('.sp-btn-close-msg i').click(function() {
    $('.in-crisis-box-splash-page').slideUp('faster');
  });

  // In Crisis Message Secondary Pages
  // In Crisis Box is opening
  $('.in-crisis-arrow').click(function() {

    $('.in-crisis-box').addClass('cookie-open').slideDown({
      complete: function() {
        var crisisBoxHeight = $('.in-crisis-box').height();
        $('main').css('paddingTop', crisisBoxHeight+'px');
        $('.header').css('paddingTop', crisisBoxHeight+50+'px');
        var totalHeader = $('.header').outerHeight();
        $('.header__navigation_mobile').css('top', totalHeader+'px');
      }
    });
  });

  // In Crisis Box is closing
  $('.btn-close-msg i').click(function() {
    $('.in-crisis-box').slideUp();
    $('main').css('paddingTop', '0px');
    $('.header').removeAttr("style");
    var totalHeader = $('.header').outerHeight();
    $('.header__navigation_mobile').css('top', totalHeader+'px');
  });


  // Province or Territory Dropdown
  $('.province-box-selection').click(function() {
    $('.province-dropdown').slideToggle();
    $('.dropdown-image').toggleClass('dropdown-arrow-inverted');
  });

  // English Version
  $('.box').on('click', function(){
    var value = $(this).attr('name');
    $('.province-choose').replaceWith("<span class='province-choose'>" + value + "</span>");
    $('.province-dropdown').hide();
    $('.dropdown-image').removeClass('dropdown-arrow-inverted');
    if( value === "Ontario") {
      $('#province-btn').removeClass('disabled').attr("href", "https://bouncebackontario.ca/");
    } else if (value === "British Columbia") {
      $('#province-btn').removeClass('disabled').attr("href", "https://bouncebackbc.ca/");
    } else if (value === "New Brunswick") {
      $('#province-btn').removeClass('disabled').attr("href", "https://cmhanb.ca/bounceback/");
    } else {
      $('#province-btn').removeClass('disabled').attr("href", document.location.pathname + "welcome");
      // $('#province-btn').addClass('disabled').attr("href", "#");
    }
    // alert(value);
  });

  // French Version
  $('.box-fr').on('click', function(){
    var value = $(this).attr('name');
    $('.province-choose').replaceWith("<span class='province-choose'>" + value + "</span>");
    $('.province-dropdown').hide();
    $('.dropdown-image').removeClass('dropdown-arrow-inverted');
    if( value === "Ontario") {
      $('#province-btn').removeClass('disabled').attr("href", "https://bouncebackontario.ca/fr/");
    } else if (value === "Colombie-Britannique") {
      $('#province-btn').removeClass('disabled').attr("href", "https://bouncebackbc.ca/");
    } else if (value === "Nouveau-Brunswick") {
      $('#province-btn').removeClass('disabled').attr("href", "https://cmhanb.ca/fr/retrouver-son-entrain/");
    } else {
      $('#province-btn').removeClass('disabled').attr("href", document.location.pathname + "bienvenue");
      // $('#province-btn').addClass('disabled').attr("href", "#");
    }
    // alert(value);
  });


  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      $('.back-to-top').fadeIn();
    } else {
      $('.back-to-top').fadeOut();
    }
  });

  // HOMEAPAGE COUNTER
  var waypoints = $('.join-us').waypoint(function(direction) {
    var countElement = $(this.element).find('.countUp');
    console.log(countElement);
    countElement.each(function(){
        var count = $(this).attr('data-value');
        var suffix = $(this).attr('data-suffix');
        var options = {
        useEasing : true,
        useGrouping : true,
        separator : ' ',
        decimal : '.',
        suffix : suffix
      };

      var numAnim = new CountUp(this, 0, count, 0, 3, options);
      numAnim.start();
    });
    this.destroy();
  },{
    offset: '80%'
  });


  // If the 'hide cookie is not set we show the message
  if (!readCookie('hide')) {
    $('.in-crisis-box').addClass('cookie-open');
    var crisisBoxHeight = $('.in-crisis-box').height();
    $('main').css('paddingTop', crisisBoxHeight+'px');
    $('.header').css('paddingTop', crisisBoxHeight+40+'px');
    var totalHeader = $('.header').outerHeight();
    $('.header__navigation_mobile').css('top', totalHeader+'px');
  }


  //Ajust height of main div on resize crisis box
  $( window ).resize(function() {
    if ($('.in-crisis-box').hasClass('cookie-open')) {
      var crisisBoxHeight = $('.in-crisis-box').height();
      $('main').css('paddingTop', crisisBoxHeight+'px');
    }
  });

  $('.in-crisis-arrow').click(function() {
    $('.in-crisis-box').addClass('cookie-open');
    $('.language-toggle-menu').addClass('ltm-top-opened').removeClass('ltm-top-closed');
    eraseCookie('hide');
    return false;
  });

  $('.btn-close-msg i').click(function() {
    $('.in-crisis-box').removeClass('cookie-open').hide();
    $('.language-toggle-menu').removeClass('ltm-top-opened').addClass('ltm-top-closed');
    createCookie('hide', true, 1);
    return false;
  });
})(jQuery); // Fully reference jQuery after this point.
